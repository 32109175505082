import { CreateRepairment } from "../../modules/Repairment";
import { AuthProvider } from "../../providers";

export const CreateRepairmentPage = () => {
  return (
    <AuthProvider>
      <CreateRepairment />
    </AuthProvider>
  );
};

export default CreateRepairmentPage;
