import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StyleProvider } from "@ant-design/cssinjs";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./routes/home";
import LoginPage from "./routes/login";
import { ConfigProvider } from "antd";
import CreateRepairmentPage from "./routes/repairments/create";
import ViewRepairmentPage from "./routes/repairments/view";
import RepairmentReportPage from "./routes/repairments/report";
import ReturnReportPage from "./routes/repairments/return";
import ReceiveReportPage from "./routes/reports/receive";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/reports/repairments",
    element: <RepairmentReportPage />,
  },
  {
    path: "/reports/return",
    element: <ReturnReportPage />,
  },
  {
    path: "/reports/receive",
    element: <ReceiveReportPage />,
  },
  {
    path: "/repairments/create",
    element: <CreateRepairmentPage />,
  },
  {
    path: "/repairments/:uuid",
    element: <ViewRepairmentPage />,
  },
  {
    path: "/repairs/:id",
  },
]);

root.render(
  <React.StrictMode>
    <ConfigProvider theme={{}}>
      <StyleProvider hashPriority="high">
        <RouterProvider router={router} />
      </StyleProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
