import { FC, PropsWithChildren } from "react";
import { useMyAuth } from "../modules/Authentication/hooks";
import { AuthContext } from "../hooks";
import { Spin } from "antd";
import { Login } from "../modules/Authentication";
import { AdminMenu } from "./Menu";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, loading } = useMyAuth();
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
      }}
    >
      <>
        {user && <AdminMenu />}
        <div className="p-8">
          <Spin spinning={loading}>
            {!loading && !user && <Login />}
            {user && (
              <Spin spinning={loading}>
                <div className="container max-w-screen-lg mx-auto">
                  {children}
                </div>
              </Spin>
            )}
          </Spin>
        </div>
      </>
    </AuthContext.Provider>
  );
};
