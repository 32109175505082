import { Typography } from "antd";
import { RepairmentReport } from "./components";

export const Report = () => {
  return (
    <div>
      <div className="flex justify-center flex-col items-center mb-10">
        <Typography.Title level={1} className="!m-0">
          สรุปยอดงานซ่อม
        </Typography.Title>
      </div>

      <RepairmentReport />
    </div>
  );
};
