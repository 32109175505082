import { Button, Input } from "antd";
import { Link } from "react-router-dom";
import { ReceiptList } from "./components";
import { useState } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

export const Receipt = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  return (
    <>
      <div className="flex items-center gap-4">
        <div>
          <div>TEL NO.</div>
          {/* <div>เบอร์โทรศัพท์</div> */}
        </div>
        <div className="flex-1">
          <Input.Search
            placeholder="Phone number"
            size="large"
            onSearch={(value) => setSearchTerm(value)}
            enterButton={
              <Button block type="primary" icon={<SearchOutlined />}>
                Search
              </Button>
            }
          />
        </div>

        <Link to="/repairments/create">
          <Button
            ghost
            block
            type="primary"
            size="large"
            icon={<PlusOutlined />}
          >
            New
          </Button>
        </Link>
      </div>

      <div className="mt-5"></div>
      <div className="mt-4">
        <ReceiptList searchTerm={searchTerm} />
      </div>
    </>
  );
};
