import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useFirebaseClient } from "./useFirebaseClient";

export const useFileUpload = () => {
  const app = useFirebaseClient();
  const storage = getStorage(app);
  return {
    upload: (filePath: string, file: File) => {
      const fileRef = ref(storage, filePath);
      try {
        return uploadBytes(fileRef, file).then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        });
      } catch (error) {
        throw error;
      }
    },
  };
};
