import { doc, getFirestore, runTransaction } from "firebase/firestore";
import { useFirebaseClient } from "../../../hooks";
import { useState } from "react";
import { IReceipt, IRepair } from "../../../entities";
import { v4 } from "uuid";
import { IHistory } from "../../../entities/Receipt";
import { isBefore, sub } from "date-fns";
import { notification } from "antd";

export const useUpdateRepairment = () => {
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (
    values: IReceipt,
    signatureData: string,
    newReturnItems: IRepair[],
    isNotSaveReturn?: boolean
  ) => {
    setLoading(true);
    try {
      const receiptRef = doc(db, "repairment-receipts", values.uuid);

      const filterReturnItems = (item: IRepair) => {
        if (isNotSaveReturn) {
          return !item.isReturn;
        }
        return true;
      };

      await runTransaction(db, async (transaction) => {
        const onlyReceipt = { ...values };
        delete onlyReceipt.items;
        delete onlyReceipt.isNotSaveReturn;
        delete onlyReceipt.disabled;
        transaction.update(receiptRef, onlyReceipt);
        const isCompleted = (values.items ?? []).every((i) => i.isReturn);
        const totalPrice = (values.items ?? []).reduce(
          (prev, curr) => prev + (curr.price || 0),
          0
        );

        let completedAt: Date | null = null;

        if (isCompleted) {
          let lastReturned = sub(new Date(), { years: 100 });
          (values.items ?? []).forEach((item) => {
            const dateStr = item.returnAt!.toString();
            if (isBefore(lastReturned, new Date(dateStr))) {
              lastReturned = new Date(dateStr);
            }
          });
          completedAt = lastReturned;
          transaction.update(receiptRef, {
            returnAt: lastReturned,
            totalPrice,
          });
        }
        values.items?.forEach((item) => {
          const repairmentRef = doc(
            db,
            "repairment-receipts",
            receiptRef.id,
            "items",
            item.uuid
          );
          const itemRef = doc(db, "repairment-items", item.uuid);
          let data: any = {
            isRepairAccepted: item.isRepairAccepted,
            customerNote: item.customerNote || "",
            note: item.note || "",
            price: item.price,
            receiptNo: item.receiptNo || "",
            receiptUUID: receiptRef.id,
            updatedAt: new Date(),
          };
          if (completedAt) {
            data.completedAt = completedAt;
          }
          console.log(data);
          transaction.update(repairmentRef, data);
          transaction.update(itemRef, data);
        });
        values.items?.filter(filterReturnItems).forEach((item) => {
          const data: any = {
            ...item,
            customerNote: item.customerNote || "",
            customerName: values.name,
            receivedAt: new Date(values.receivedAt!.toString()),
            price: item.price,
            receiptNo: item.receiptNo || "",
            receiptUUID: receiptRef.id,
            updatedAt: new Date(),
          };
          if (completedAt) {
            data.completedAt = completedAt;
          }
          if (item.returnAt) {
            data.returnAt = new Date(item.returnAt.toString());
          }
          const repairmentRef = doc(
            db,
            "repairment-receipts",
            receiptRef.id,
            "items",
            item.uuid
          );
          const itemRef = doc(db, "repairment-items", item.uuid);
          transaction.update(itemRef, data);
          transaction.update(repairmentRef, data);
        });

        if (newReturnItems.filter(filterReturnItems).length > 0) {
          const historyRef = doc(
            db,
            "repairment-receipts",
            values.uuid,
            "history",
            v4()
          );
          const history: IHistory = {
            returnAt: new Date(),
            returnItems: newReturnItems
              .filter(filterReturnItems)
              .map((i) => i.uuid),
            signature: signatureData,
          };
          transaction.set(historyRef, history);
        }
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error,
      });
      console.log(error);
    }
    setLoading(false);
  };
  return {
    update: handleUpdate,
    loading,
  };
};
