import { Button, Card, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogin } from "./hooks";
import { useEffect, useState } from "react";

interface LoginForm {
  username: string;
  password: string;
}
export const Login = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const { login } = useLogin();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: LoginForm) => {
    setLoading(true);
    try {
      const response = await login(
        `${values.username}@firebaseruengcharoen.com`,
        values.password
      );
      navigate("/");
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (ctx.loading) return;
    if (ctx.user) {
      navigate("/");
    }
  }, [ctx]);

  return (
    <div className="flex justify-center mt-56">
      <Card
        className="container max-w-96 !border-blue-500"
        title="RC Watch Repair"
      >
        <Form<LoginForm> layout="vertical" onFinish={handleLogin}>
          <Form.Item label="Username" name="username">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>

          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};
