import { Button, Col, DatePicker, Form, Row, Spin, notification } from "antd";
import SignatureCanvas from "react-signature-canvas";

import { useNavigate } from "react-router-dom";
import { RepairmentForm } from "./RepairmentForm";
import { IReceipt } from "../../../entities";
import { useCreateRepairment } from "../hooks";
import { useMemo, useRef, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Printer } from "../utils/printer";
import dayjs from "dayjs";

const printer = new Printer();
export const RepairmentCreateForm = () => {
  const { loading, create: createRepairment } = useCreateRepairment();
  const navigate = useNavigate();
  const [signatureDirty, setSignatureDirty] = useState(false);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [form] = Form.useForm<IReceipt>();
  const values = Form.useWatch([], form);
  const handleSubmit = async (values: IReceipt) => {
    try {
      await createRepairment({
        ...values,
        receiveSignature: signatureRef.current?.toDataURL(),
        receivedAt: new Date(values.receivedAt!.toString()),
      });

      const canvas = signatureRef.current?.getTrimmedCanvas();
      const ctx = canvas?.getContext("2d");
      printer.printReceiptConfirmation({
        ...values,
        receivedAt: new Date(values.receivedAt!.toString()),
        signaturedData: ctx?.getImageData(
          0,
          0,
          canvas?.width!,
          canvas?.height!
        ),
      });
      notification.success({
        message: "Success",
        description: "Create receipt successfully",
      });
      navigate("/");
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  const canvasWidth = useMemo(() => {
    const windowWidth = window.document.body.clientWidth;
    if (windowWidth < 500) return windowWidth - 64;
    return 500;
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Form<IReceipt>
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          className="w-full"
          onFinish={handleSubmit}
          initialValues={{
            name: "",
            phoneNumber: "",
            contact: "",
            receivedAt: dayjs(),
          }}
        >
          <Form.Item
            label={
              <div className="mt-2">
                RECEIPT DATE <br /> วันที่รับของ
              </div>
            }
            name="receivedAt"
            rules={[{ required: true }]}
          >
            <DatePicker
              format="DD MMM YYYY"
              size="large"
              style={{ width: 250 }}
            />
          </Form.Item>
          <RepairmentForm isCreate />
          <Form.Item
            label={
              <div className="mt-2">
                SIGNATURE <br /> ลายเซ็นต์ลูกค้า
              </div>
            }
          >
            <div className="flex items-end flex-row">
              <div>
                <div className="border rounded">
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    backgroundColor="white"
                    maxWidth={1}
                    minWidth={1}
                    canvasProps={{
                      width: canvasWidth,
                      height: 300,
                      className: "sigCanvas",
                    }}
                    onBegin={() => setSignatureDirty(true)}
                  />
                </div>

                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    setSignatureDirty(false);
                    signatureRef.current?.clear();
                  }}
                />
              </div>
            </div>
          </Form.Item>

          <Row>
            <Col offset={4}>
              <Button
                size="large"
                disabled={!signatureDirty || (values.items ?? []).length === 0}
                loading={loading}
                htmlType="submit"
                type="primary"
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
