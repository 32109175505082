import { Login } from "../../modules/Authentication";
import { AuthProvider } from "../../providers";

const LoginPage = () => {
  return (
    <AuthProvider>
      <Login />
    </AuthProvider>
  );
};

export default LoginPage;
