import { useMemo, useState } from "react";
import { sub } from "date-fns";
import { useItems } from "../hooks";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Modal,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import { IRepair } from "../../../entities";
import { EyeOutlined } from "@ant-design/icons";
import { ItemReportType } from "../hooks/useItems";

type IReportItem = {
  dateFormatted: string;
  items: IRepair[];
  quantity: number;
  totalPrice: number;
};
export const RepairmentReport = () => {
  const [startAt, setStartAt] = useState<Date>(sub(new Date(), { days: 7 }));
  const [endAt, setEndAt] = useState<Date>(new Date());
  const [selectedRow, setSelectedRow] = useState<IReportItem>();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [filterEmpty, setFilterEmpty] = useState<boolean>(false);

  const { loading, data } = useItems(startAt, endAt, ItemReportType.Repair);

  const reportItems: IReportItem[] = useMemo(() => {
    // loop through date from startAt to endAt and summary the quantity and totalPrice
    const items: IReportItem[] = [];
    const date = Object.assign(new Date(startAt));
    let sumTotalPrice = 0;
    while (date <= endAt) {
      const itemsByDate = data.filter(
        (item) =>
          dayjs(item.returnAt).format("DD MMM YYYY") ===
          dayjs(date).format("DD MMM YYYY")
      );
      const quantity = itemsByDate.length;
      const totalPrice = itemsByDate.reduce((acc, item) => acc + item.price, 0);
      sumTotalPrice += totalPrice;
      items.push({
        dateFormatted: dayjs(date).format("DD MMM YYYY"),
        items: itemsByDate,
        quantity,
        totalPrice,
      });
      date.setDate(date.getDate() + 1);
    }
    const summaryRow: IReportItem = {
      dateFormatted: "รวม",
      items: [],
      quantity: items.reduce((acc, item) => acc + item.quantity, 0),
      totalPrice: sumTotalPrice,
    };
    if (filterEmpty) {
      return items.filter((item) => item.quantity > 0).concat(summaryRow);
    }
    return items.concat(summaryRow);
  }, [data, startAt, endAt, filterEmpty]);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex gap-3">
          <Form.Item label="วันที่">
            <DatePicker
              format={"DD MMM YYYY"}
              value={dayjs(startAt)}
              onChange={(date) => {
                if (date) {
                  setStartAt(date.toDate());
                }
              }}
            />
          </Form.Item>

          <Form.Item label="ถึง">
            <DatePicker
              format={"DD MMM YYYY"}
              value={dayjs(endAt)}
              onChange={(date) => {
                if (date) {
                  setEndAt(date.toDate());
                }
              }}
            />
          </Form.Item>

          <div>
            <Checkbox
              checked={filterEmpty}
              onChange={(e) => setFilterEmpty(e.target.checked)}
            >
              Filter Empty
            </Checkbox>
          </div>
        </div>
      </div>

      <Table<IReportItem>
        bordered
        loading={loading}
        dataSource={reportItems}
        pagination={false}
        rowKey="dateFormatted"
      >
        <Table.Column
          title="วันที่"
          align="center"
          dataIndex="dateFormatted"
          render={(text, record: IReportItem) => {
            if (record.dateFormatted === "รวม") {
              return <strong>{text}</strong>;
            }
            return text;
          }}
        />
        <Table.Column
          title="จำนวน"
          align="center"
          dataIndex="quantity"
          render={(text, record: IReportItem) => {
            if (record.dateFormatted === "รวม") {
              return <strong>{text}</strong>;
            }
            return (
              <Space>
                <span>{text}</span>
                {record.items.length > 0 && (
                  <Button
                    type="link"
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setOpenDetail(true);
                      setSelectedRow(record);
                    }}
                  />
                )}
              </Space>
            );
          }}
        />
        <Table.Column
          title="ยอดเงิน (บาท)"
          align="right"
          dataIndex="totalPrice"
          render={(text: number, record: IReportItem) => {
            if (record.dateFormatted === "รวม") {
              return <strong>{text.toLocaleString()}</strong>;
            }
            return text.toLocaleString();
          }}
        />
      </Table>

      <Modal
        destroyOnClose
        open={openDetail}
        onCancel={() => setOpenDetail(false)}
        footer={null}
        width={1000}
        title={`รายละเอียดวันที่ ${selectedRow?.dateFormatted}`}
      >
        <Table<IRepair>
          bordered
          scroll={{ x: 800 }}
          dataSource={selectedRow?.items}
          pagination={false}
        >
          <Table.Column
            title="รูปถ่าย"
            width={100}
            render={(text, record: IRepair) => {
              return <Image src={record.photoURL} width={100} />;
            }}
          />
          <Table.Column
            width={200}
            title="เลขที่ใบเสร็จ"
            dataIndex="receiptNo"
          />
          <Table.Column title="รายการซ่อม" dataIndex="note" />
          <Table.Column
            width={200}
            title="ยอดเงิน (บาท)"
            align="right"
            dataIndex="price"
            render={(text: number, record: IReportItem) => {
              return text.toLocaleString();
            }}
          />
        </Table>
      </Modal>
    </>
  );
};
