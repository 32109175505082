import { IReceipt } from "../../../entities";
import { format } from "date-fns";
import { useFirebaseClient } from "../../../hooks";
import { getDatabase, ref, set } from "firebase/database";

const ALIGN_LEFT = 0; // Left alignment
const ALIGN_CENTER = 1; // Center alignment
const ALIGN_RIGHT = 2; // Right alignment

var device = new window.EscPosPrinter(384);
export class Printer {
  constructor() {}
  app = useFirebaseClient();

  printCustomer(value: IReceipt) {
    device.setAlignment(ALIGN_LEFT);
    device.appendText(`Name: ${value.name}`);
    device.lineFeed(1);
    device.appendText(`Phone: ${value.phoneNumber}`);
    device.lineFeed(1);
    device.appendText("Date: " + format(new Date(), "dd MMM yyyy"));
    device.lineFeed(2);
  }

  printReceiptConfirmation(value: IReceipt) {
    device.clear();
    printHeader({
      title: "ใบยืนยันรับของ",
      subTitle: "/ RECEIPT CONFIRMATION",
    });
    this.printCustomer(value);
    device.appendText(`TOTAL SHIPMENT: ${value.items?.length}`);
    device.lineFeed(2);
    if (value.signaturedData) {
      device.appendText(`Signature:`);
      device.appendImage(value.signaturedData, "threshold");
    }

    device.lineFeed(4);
    this.cloudPrint();
    setTimeout(() => {
      this.cloudPrint();
    }, 500);
    // device.print(ip, "", 1);
  }

  printRepairItems(value: IReceipt) {
    const repairItems = (value.items || []).filter((item) => {
      return item.isRepairAccepted;
    });
    device.clear();
    printHeader({
      title: "ใบยืนยันรับซ่อม",
      subTitle: "/ REPAIR CONFIRMATION",
    });
    this.printCustomer(value);
    let totalPrice = 0;
    device.setCharacterSize(1, 1);
    repairItems.forEach((item, index) => {
      totalPrice += item.price;
      device.setAlignment(ALIGN_LEFT);
      device.appendText(item.note);
      device.lineFeed(1);
      device.setAlignment(ALIGN_RIGHT);
      device.appendText(item.price.toFixed(2));
      device.lineFeed(1);
    });

    device.setAlignment(ALIGN_LEFT);
    device.appendText("TOTAL");
    device.lineFeed(1);
    device.setAlignment(ALIGN_RIGHT);
    device.appendText(totalPrice.toFixed(2));

    device.lineFeed(2);
    device.setAlignment(ALIGN_CENTER);
    device.appendText(`THANK YOU FOR CHOOSING US.`);
    device.lineFeed(4);

    device.cutPaper(true);
    this.cloudPrint();
    // device.print(ip, "", 1);
  }

  printReturnItems(
    value: IReceipt,
    itemCount: number,
    dateFormatted: string,
    signaturedData: ImageData | undefined
  ) {
    device.clear();
    printHeader({
      title: "ใบยืนยันรับของคืน",
      subTitle: "/ RETURN CONFIRMATION",
    });
    this.printCustomer(value);
    device.appendText(`TOTAL SHIPMENT: ${itemCount}`);
    device.lineFeed(2);
    if (signaturedData) {
      device.appendText(`Signature:`);
      device.appendImage(signaturedData, "threshold");
    }
    device.lineFeed(1);
    device.setAlignment(ALIGN_CENTER);
    device.appendText(`THANK YOU FOR CHOOSING US.`);
    device.lineFeed(1);
    device.appendText(
      "BY SIGNING THIS RECEIPT. YOU CONFIRM THAT YOUR WATCHES HAVE BEEN RETUNRED TO YOU BY OUR STORE ON " +
        dateFormatted
    );
    device.lineFeed(4);
    // device.print(ip, "", 1);
    this.cloudPrint();
  }

  cloudPrint() {
    const printData = device.orderData;
    const printRef = ref(
      getDatabase(this.app),
      "printer/queue/" + new Date().getTime()
    );
    set(printRef, printData);
  }
}
const printHeader = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) => {
  device.clear();
  device.setAlignment(ALIGN_CENTER);
  device.lineFeed(1);
  device.setPrintModes(true, true, true);
  device.appendText(title);
  device.lineFeed(1);
  device.lineFeed(1);
  device.setPrintModes(false, false, false);
  device.appendText(subTitle);
  device.lineFeed(2);

  device.setCharacterSize(1, 1);
  device.appendText("Service and repair watch");
  device.lineFeed(1);
  device.appendText("Mr.Jack Watch shop");
  device.lineFeed(1);
  device.appendText("mr.jackwatches@gmai.com");
  device.lineFeed(1);
  device.appendText("099-919-9499, 097-879-3535");

  device.lineFeed(2);
};
