import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { useFirebaseClient } from "../../../hooks";
import { useEffect, useState } from "react";

export const useMyAuth = () => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);
  const app = useFirebaseClient();
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
      setLoading(false);
    });
  }, []);
  return {
    user,
    loading,
  };
};
