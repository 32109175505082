import { runTransaction, getFirestore, doc } from "firebase/firestore";
import { useFirebaseClient } from "../../../hooks";
import { useState } from "react";
import { IReceipt } from "../../../entities";
import { v4 } from "uuid";

export const useCreateRepairment = () => {
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [loading, setLoading] = useState(false);

  const handleCreate = async (values: IReceipt) => {
    setLoading(true);
    const receiptRef = doc(db, "repairment-receipts", v4());
    const repairments = values.items
      ?.filter((item) => item.photoURL !== "")
      .map((item) => {
        return {
          uuid: v4(),
          customerName: values.name,
          customerNote: item.customerNote,
          photoURL: item.photoURL,
          price: item.price,
          receivedAt: values.receivedAt,
          receiptNo: item.receiptNo || "",
          receiptUUID: receiptRef.id,
          createdAt: new Date(),
          updatedAt: new Date(),
        };
      });
    const receipt = {
      totalItems: repairments?.length || 0,
      uuid: receiptRef.id,
      name: values.name,
      phoneNumber: values.phoneNumber,
      note: values.note || "",
      status: "received",
      contact: values.contact || "",
      receiveSignature: values.receiveSignature || "",
      receivedAt: values.receivedAt,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    try {
      await runTransaction(db, async (transaction) => {
        transaction.set(receiptRef, receipt);
        repairments?.forEach((repairment) => {
          const repairmentRef = doc(
            db,
            "repairment-receipts",
            receiptRef.id,
            "items",
            repairment.uuid
          );
          const itemRef = doc(db, "repairment-items", repairment.uuid);
          transaction.set(itemRef, repairment);
          transaction.set(repairmentRef, repairment);
        });
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  return {
    create: handleCreate,
    loading,
  };
};
