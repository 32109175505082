import { useParams } from "react-router-dom";
import { ViewRepairment } from "../../modules/Repairment";
import { AuthProvider } from "../../providers";

export const ViewRepairmentPage = () => {
  const { uuid } = useParams();
  return (
    <AuthProvider>
      <ViewRepairment uuid={uuid!} />
    </AuthProvider>
  );
};

export default ViewRepairmentPage;
