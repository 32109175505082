import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

import { useEffect, useState } from "react";
import { IRepair } from "../../../entities";
import { useFirebaseClient } from "../../../hooks";

export enum ItemReportType {
  Receive = "receive",
  Return = "return",
  Repair = "repair",
}
export const useItems = (
  startAt: Date,
  endAt: Date,
  reportType: ItemReportType
) => {
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [data, setData] = useState<IRepair[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    let q = query(
      collection(db, "repairment-items"),
      where("isReturn", "==", true),
      where("returnAt", ">=", startAt),
      where("returnAt", "<=", endAt)
    );
    switch (reportType) {
      case ItemReportType.Receive:
        q = query(
          collection(db, "repairment-items"),
          where("receivedAt", ">=", startAt),
          where("receivedAt", "<=", endAt)
        );
        break;
      case ItemReportType.Repair:
        q = query(
          collection(db, "repairment-items"),
          where("isReturn", "==", true),
          where("returnAt", ">=", startAt),
          where("returnAt", "<=", endAt),
          where("isRepairAccepted", "==", true)
        );
        break;
      case ItemReportType.Return:
        q = query(
          collection(db, "repairment-items"),
          where("isReturn", "==", true),
          where("returnAt", ">=", startAt),
          where("returnAt", "<=", endAt)
        );
    }
    getDocs(q).then((querySnapshot) => {
      const items: IRepair[] = [];
      querySnapshot.forEach((doc) => {
        const data = {
          ...doc.data(),
          receivedAt: doc.data().receivedAt?.toDate(),
          returnAt: doc.data().returnAt?.toDate(),
          completedAt: doc.data().completedAt?.toDate(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate(),
        };

        items.push(data as IRepair);
      });
      setData(items);
      setLoading(false);
    });
  }, [startAt, endAt]);
  return {
    loading,
    data,
  };
};
