import {
  collection,
  doc,
  endAt,
  getDocs,
  getFirestore,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { IReceipt } from "../../../entities";
import { useFirebaseClient } from "../../../hooks";
import { useEffect, useState } from "react";

export const useReceipts = (phoneNumber?: string) => {
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [data, setData] = useState<IReceipt[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (!phoneNumber) {
      return;
    }

    setLoading(true);
    const q = query(
      collection(db, "repairment-receipts"),
      orderBy("phoneNumber"),
      startAt(phoneNumber),
      endAt(phoneNumber + "\uf8ff")
      // orderBy("createdAt", "desc")
    );

    getDocs(q).then((querySnapshot) => {
      const receipts: IReceipt[] = [];
      querySnapshot.forEach((doc) => {
        const data = {
          ...doc.data(),
          returnAt: doc.data().returnAt?.toDate(),
          receivedAt: (doc.data().receivedAt || doc.data().createdAt).toDate(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate(),
        };

        receipts.push(data as IReceipt);
      });
      setData(receipts);
      setLoading(false);
      if (receipts.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    });
  }, [phoneNumber]);

  return {
    loading: loading,
    notFound: notFound,
    data: data,
  };
};
