import { Typography } from "antd";
import { AuthProvider } from "../../providers";
import { Receipt } from "../../modules/Repairment";

const HomePage = () => {
  return (
    <AuthProvider>
      <div>
        <div className="flex justify-center flex-col items-center">
          <Typography.Title level={1} className="!m-0">
            Receipt / Return Watches
          </Typography.Title>
          <Typography.Title level={4} className="!mt-2">
            รับ / คืน นาฬิกา
          </Typography.Title>
        </div>

        <div className="container max-w-screen-lg mx-auto">
          <Receipt />
        </div>
      </div>
    </AuthProvider>
  );
};

export default HomePage;
