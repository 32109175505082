import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useFirebaseClient } from "../../../hooks";

export const useLogin = () => {
  const app = useFirebaseClient();
  const auth = getAuth(app);

  return {
    login: async (username: string, password: string) => {
      return signInWithEmailAndPassword(auth, username, password);
    },
  };
};
