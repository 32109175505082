import { Typography } from "antd";
import { ReceiveReport } from "../../modules/Repairment/components";
import { AuthProvider } from "../../providers";

export const ReceiveReportPage = () => {
  return (
    <AuthProvider>
      <div className="flex justify-center flex-col items-center mb-10">
        <Typography.Title level={1} className="!m-0">
          Receive History
        </Typography.Title>
      </div>

      <ReceiveReport />
    </AuthProvider>
  );
};
export default ReceiveReportPage;
