import { User } from "firebase/auth";
import { createContext, useContext } from "react";

interface AuthContextProps {
  user?: User;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  loading: true,
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};
