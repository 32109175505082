import { Typography } from "antd";
import { ReturnReport } from "../../modules/Repairment/components";
import { AuthProvider } from "../../providers";

export const ReturnReportPage = () => {
  return (
    <AuthProvider>
      <div className="flex justify-center flex-col items-center mb-10">
        <Typography.Title level={1} className="!m-0">
          Return History
        </Typography.Title>
      </div>

      <ReturnReport />
    </AuthProvider>
  );
};
export default ReturnReportPage;
