import { Report } from "../../modules/Repairment";
import { AuthProvider } from "../../providers";

export const RepairmentReportPage = () => {
  return (
    <AuthProvider>
      <Report />
    </AuthProvider>
  );
};
export default RepairmentReportPage;
