import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Space,
  Upload,
  message,
  notification,
} from "antd";
import { FC, useEffect, useState } from "react";
import { v4 } from "uuid";
import { IReceipt, IRepair } from "../../../entities";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import {
  CheckOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FieldNumberOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useFileUpload } from "../../../hooks";
import clsx from "clsx";
import dayjs from "dayjs";

export const RepairmentForm: FC<{ isCreate: boolean }> = ({ isCreate }) => {
  return (
    <>
      <Form.Item
        label={
          <div className="mt-2">
            TEL NO <br /> เบอร์โทรศัพท์
          </div>
        }
        name="phoneNumber"
        rules={[{ required: true }]}
      >
        <Input size="large" disabled={!isCreate} />
      </Form.Item>
      <Form.Item
        label={
          <div className="mt-2">
            NAME <br /> ชื่อ
          </div>
        }
        name="name"
        rules={[{ required: true }]}
      >
        <Input size="large" disabled={!isCreate} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            CONTACT <br />
            ช่องทางติดต่ออื่นๆ
          </div>
        }
        name="contact"
      >
        <Input size="large" disabled={!isCreate} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            WATCHES LIST <br /> รายการนาฬิกา
          </div>
        }
      >
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <div>
                <div
                  className={clsx(
                    isCreate && "gap-2 grid grid-cols-2",
                    "w-full"
                  )}
                >
                  {fields.map((field, index) => {
                    return (
                      <div
                        key={field.key}
                        className={clsx(isCreate && "w-full relative flex")}
                      >
                        {isCreate && (
                          <span className="absolute top-0 right-0 z-10">
                            <Button
                              onClick={() => remove(field.name)}
                              size="small"
                              icon={<CloseOutlined />}
                            />
                          </span>
                        )}
                        <Form.Item {...field} className="!m-0 !w-full">
                          <RepairmentItem
                            isCreate={isCreate}
                            name={["items", field.name]}
                            index={field.name}
                            key={field.name}
                          />
                        </Form.Item>
                      </div>
                    );
                  })}
                </div>
                {isCreate && (
                  <Button
                    block
                    ghost
                    type="primary"
                    onClick={() =>
                      add({
                        price: 0,
                        photoURL: "",
                        receiptNo: "",
                        customerNote: "",
                        isRepairAccepted: false,
                      } as IRepair)
                    }
                  >
                    Add
                  </Button>
                )}
              </div>
            );
          }}
        </Form.List>
      </Form.Item>
    </>
  );
};

const RepairmentItem: FC<{
  repairment?: IRepair;
  name: any[];
  index: number;
  isCreate: boolean;
}> = ({ repairment, index, name, isCreate }) => {
  const form = Form.useFormInstance<IReceipt>();
  const { upload } = useFileUpload();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const handleUpload = async (req: any) => {
    setLoading(true);
    const file = req.file as RcFile;
    const ext = file.name.split(".").pop();
    const randomFileName = v4();
    const filePath = `repairments/${randomFileName}.${ext}`;
    try {
      const url = await upload(filePath, file);
      setImageUrl(url);
      const value = form.getFieldValue(name);
      form.setFieldValue(name, {
        ...value,
        photoURL: url,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
    setLoading(false);
  };
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    const value = form.getFieldValue(name);
    if (!value) {
      return;
    }
    setImageUrl(value.photoURL);
  }, [name]);

  return (
    <>
      <Card size="small" className="!mb-2">
        <Form.Item shouldUpdate className="!m-0">
          {(f) => {
            const disabled = f.getFieldValue(name).disabled;
            const value = f.getFieldValue(name);
            return (
              <div className="flex  gap-2">
                <div className={clsx(isCreate && "w-full")}>
                  {isCreate ? (
                    <div className="w-full">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader text-center"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        accept="image/*"
                        customRequest={handleUpload}
                      >
                        {imageUrl ? (
                          <Image src={value.photoURL} />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      <Form.Item
                        className="!mb-0"
                        name={[index, "photoURL"]}
                        rules={[
                          { required: true, message: "Please upload photo" },
                        ]}
                      ></Form.Item>
                      <Form.Item name={[index, "customerNote"]}>
                        <Input.TextArea
                          rows={3}
                          style={{ width: "100%" }}
                          placeholder="หมายเหตุ"
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="w-[100px]">
                      <Image src={value.photoURL} />
                    </div>
                  )}
                </div>
                <Form.Item hidden name={[index, "disabled"]} />
                {!isCreate && (
                  <>
                    <div className="w-2/3 ml-7">
                      {disabled && (
                        <>
                          <div>
                            <Space>
                              <CheckOutlined className="text-green-500" />
                              <span>
                                คืนสินค้าแล้วเมื่อ:{" "}
                                {value.returnAt.format("DD MMM YYYY HH:mm")}
                              </span>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              {value.isRepairAccepted ? (
                                <CheckOutlined className="text-green-500" />
                              ) : (
                                <CloseOutlined className="text-red-500" />
                              )}
                              <span>
                                {value.isRepairAccepted
                                  ? "รับซ่อมแล้ว"
                                  : "ไม่รับซ่อม"}
                              </span>
                            </Space>
                          </div>
                          {value.note ? (
                            <div>
                              <Space>
                                <EditOutlined />
                                <p>{value.note}</p>
                              </Space>
                            </div>
                          ) : (
                            <></>
                          )}
                          {value.receiptNo ? (
                            <div>
                              <Space>
                                <FieldNumberOutlined />
                                <span>{value.receiptNo}</span>
                              </Space>
                            </div>
                          ) : (
                            <></>
                          )}
                          {value.price ? (
                            <div>
                              <Space>
                                <DollarCircleOutlined />
                                <b>THB {value.price.toLocaleString()}</b>
                              </Space>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      <div>
                        <div className="flex gap-1">
                          <Form.Item
                            hidden={disabled}
                            className="!mb-0"
                            name={[index, "isReturn"]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked) {
                                  form.setFieldValue(
                                    ["items", index, "returnAt"],
                                    dayjs()
                                  );
                                }
                              }}
                            >
                              คืนสินค้า
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            labelCol={{ sm: 12 }}
                            wrapperCol={{ sm: 24 }}
                            className="!mb-0"
                            hidden={!value.isReturn || disabled}
                            name={[index, "returnAt"]}
                          >
                            <DatePicker
                              showTime
                              // defaultValue={dayjs()}
                              format="DD MMM YYYY HH:mm"
                              disabledDate={(current) => {
                                // disabled future date
                                return (
                                  current && current > dayjs().endOf("day")
                                );
                              }}
                              // style={{ width: 250 }}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          className="!mb-0"
                          hidden={disabled}
                          name={[index, "isRepairAccepted"]}
                          valuePropName="checked"
                        >
                          <Checkbox>รับซ่อม</Checkbox>
                        </Form.Item>
                        <Form.Item
                          className="!mb-0"
                          hidden={!value.isRepairAccepted || disabled}
                          name={[index, "note"]}
                        >
                          <Input.TextArea rows={2} />
                        </Form.Item>
                        <div>
                          {value.isRepairAccepted && !disabled && (
                            <div>เลขที่ใบเสร็จ</div>
                          )}
                          <Form.Item
                            hidden={!value.isRepairAccepted || disabled}
                            name={[index, "receiptNo"]}
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div>หมายเหตุ</div>
                        <Form.Item
                          name={[index, "customerNote"]}
                          className="!mb-0"
                        >
                          <Input.TextArea
                            style={{ color: "black" }}
                            // disabled={disabled}
                            rows={3}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div>
                      {value.isRepairAccepted && <div>ราคา</div>}
                      <Form.Item
                        hidden={!value.isRepairAccepted}
                        name={[index, "price"]}
                      >
                        <InputNumber disabled={disabled} />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Card>
    </>
  );
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
