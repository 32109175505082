import { useEffect, useState } from "react";
import { IReceipt } from "../../../entities";
import { useFirebaseClient } from "../../../hooks";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import dayjs from "dayjs";

export const useReceipt = (uuid: string) => {
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [data, setData] = useState<IReceipt>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const docRef = doc(db, "repairment-receipts", uuid);
    setLoading(true);
    getDoc(docRef).then(async (doc) => {
      if (!doc.exists()) {
        return;
      }
      const itemsRef = collection(db, "repairment-receipts", uuid, "items");
      const itemsSnapshot = await getDocs(itemsRef);
      const items = itemsSnapshot.docs.map((doc) => {
        const data: any = {
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate(),
        };
        if (data.returnAt) {
          data.returnAt = dayjs(data.returnAt.toDate());
          if (data.isReturn) {
            data.disabled = true;
          }
        }
        if (!data.isRepairAccepted) {
          data.isRepairAccepted = false;
        }
        return data;
      });
      const data = {
        ...doc.data(),
        items,
        receivedAt: (doc.data().receivedAt || doc.data().createdAt).toDate(),
        createdAt: doc.data().createdAt.toDate(),
        updatedAt: doc.data().updatedAt.toDate(),
      };
      setData(data as IReceipt);
      setLoading(false);
    });
  }, [uuid, lastUpdated]);

  return {
    data,
    refetch: () => {
      setLastUpdated(new Date());
    },
    loading,
  };
};
