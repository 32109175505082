import { format } from "date-fns";
import { useReceipts } from "../hooks";
import { Button, Result, Spin } from "antd";
import { CheckCircleFilled, EyeOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Link } from "react-router-dom";

export const ReceiptList: FC<{ searchTerm: string }> = ({ searchTerm }) => {
  const { data, loading, notFound } = useReceipts(searchTerm);
  return (
    <div>
      {loading && <Spin />}
      {notFound && <Result status="404" title="Receipt Not Found" />}
      {data
        .sort((a, b) => {
          if (a.receivedAt! > b.receivedAt!) return -1;
          if (a.receivedAt! < b.receivedAt!) return 1;
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        })
        .map((receipt) => {
          return (
            <div
              className="border p-4 rounded mt-2 bg-gray-50"
              key={receipt.uuid}
            >
              <div className="flex justify-between items-end">
                <div className="flex flex-col gap-1">
                  <div className="flex gap-1">
                    <div className="w-40">
                      <div>
                        <div>NAME:</div>
                        {/* <div>ชื่อ</div> */}
                      </div>
                    </div>
                    <div>{receipt.name}</div>
                  </div>

                  <div className="flex gap-1">
                    <div className="w-40">
                      <div>
                        <div> TEL NO:</div>
                        {/* <div>เบอร์โทรศัพท์</div> */}
                      </div>
                    </div>
                    <div>{receipt.phoneNumber}</div>
                  </div>

                  <div className="flex gap-1">
                    <div className="w-40">
                      <div>
                        <div>RECEIVED DATE:</div>
                        {/* <div>วันที่รับของ</div> */}
                      </div>
                    </div>
                    <div>
                      {format(receipt.receivedAt!, "dd MMM yyyy")}
                      {receipt.totalItems
                        ? ` (${receipt.totalItems} items)`
                        : ""}
                    </div>
                  </div>

                  <div className="flex gap-1">
                    <div className="w-40">
                      <div>
                        <div>CREATED AT:</div>
                        {/* <div>วันที่รับของ</div> */}
                      </div>
                    </div>
                    <div>{format(receipt.createdAt!, "dd MMM yyyy HH:mm")}</div>
                  </div>

                  {receipt.returnAt && (
                    <div className="flex gap-1">
                      <div className="w-40">
                        <div>
                          <div>RETURNED DATE :</div>
                          {/* <div>วันที่คืนของ</div> */}
                        </div>
                      </div>
                      <div>
                        {format(receipt.returnAt, "dd MMM yyyy")}{" "}
                        <CheckCircleFilled className="text-green-500" />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex gap-2">
                  {/* {!receipt.returnAt && (
                  <Button type="primary" icon={<SwapOutlined />}>
                    RETRUN
                  </Button>
                )} */}
                  <Link to={`/repairments/${receipt.uuid}`}>
                    <Button icon={<EyeOutlined />} type="primary">
                      VIEW
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
