import { collection, getDocs, getFirestore } from "firebase/firestore";
import { useFirebaseClient } from "../../../hooks";
import { useEffect, useState } from "react";
import { IHistory } from "../../../entities/Receipt";

export const useReturnHistory = (receiptUUID: string) => {
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [data, setData] = useState<IHistory[]>([]);
  const app = useFirebaseClient();
  const db = getFirestore(app);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const historyRef = collection(
      db,
      "repairment-receipts",
      receiptUUID,
      "history"
    );
    getDocs(historyRef).then(async (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const data: IHistory = {
          returnAt: doc.data().returnAt.toDate(),
          returnItems: doc.data().returnItems,
          signature: doc.data().signature,
        };
        return data;
      });
      setData(data);
    });

    setLoading(false);
  }, [receiptUUID, lastUpdated]);
  return {
    loading,
    data: data,
    refetch: () => {
      setLastUpdated(new Date());
    },
  };
};
