import { Typography } from "antd";
import { RepairmentViewForm } from "./components";
import { FC } from "react";

export const ViewRepairment: FC<{ uuid: string }> = ({ uuid }) => {
  return (
    <div>
      <div className="flex justify-center flex-col items-center">
        <Typography.Title level={1} className="!m-0">
          RETURN CONFIRMATION
        </Typography.Title>
        <Typography.Title level={4} className="!mt-2">
          ใบยืนยันคืนของ
        </Typography.Title>
      </div>
      <RepairmentViewForm uuid={uuid} />
    </div>
  );
};
