import { useMemo, useState } from "react";
import { sub } from "date-fns";
import { useItems } from "../hooks";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Modal,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import { IRepair } from "../../../entities";
import {
  CheckCircleFilled,
  EyeOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ItemReportType } from "../hooks/useItems";
import { ViewModal } from "./ViewModal";

type IReportItem = {
  dateFormatted: string;
  items: IRepair[];
  quantity: number;
  totalPrice: number;
};
export const ReceiveReport = () => {
  const [startAt, setStartAt] = useState<Date>(sub(new Date(), { days: 7 }));
  const [endAt, setEndAt] = useState<Date>(new Date());
  const [selectedRow, setSelectedRow] = useState<IReportItem>();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [filterEmpty, setFilterEmpty] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [selectedUUID, setSelectedUUID] = useState<string>("");

  const { loading, data } = useItems(startAt, endAt, ItemReportType.Receive);

  const reportItems: IReportItem[] = useMemo(() => {
    // loop through date from startAt to endAt and summary the quantity and totalPrice
    const items: IReportItem[] = [];
    const date = Object.assign(new Date(startAt));
    let sumTotalPrice = 0;
    while (date <= endAt) {
      const itemsByDate = data.filter(
        (item) =>
          dayjs(item.receivedAt).format("DD MMM YYYY") ===
          dayjs(date).format("DD MMM YYYY")
      );

      console.log(date, itemsByDate);
      const quantity = itemsByDate.length;
      const totalPrice = itemsByDate.reduce((acc, item) => acc + item.price, 0);
      sumTotalPrice += totalPrice;
      items.push({
        dateFormatted: dayjs(date).format("DD MMM YYYY"),
        items: itemsByDate,
        quantity,
        totalPrice,
      });
      date.setDate(date.getDate() + 1);
    }
    const summaryRow: IReportItem = {
      dateFormatted: "รวม",
      items: [],
      quantity: items.reduce((acc, item) => acc + item.quantity, 0),
      totalPrice: sumTotalPrice,
    };
    if (filterEmpty) {
      return items.filter((item) => item.quantity > 0).concat(summaryRow);
    }
    return items.concat(summaryRow);
  }, [data, startAt, endAt, filterEmpty]);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex gap-3">
          <Form.Item label="วันที่">
            <DatePicker
              format={"DD MMM YYYY"}
              value={dayjs(startAt)}
              onChange={(date) => {
                if (date) {
                  setStartAt(date.toDate());
                }
              }}
            />
          </Form.Item>

          <Form.Item label="ถึง">
            <DatePicker
              format={"DD MMM YYYY"}
              value={dayjs(endAt)}
              onChange={(date) => {
                if (date) {
                  setEndAt(date.toDate());
                }
              }}
            />
          </Form.Item>

          <div>
            <Checkbox
              checked={filterEmpty}
              onChange={(e) => setFilterEmpty(e.target.checked)}
            >
              Filter Empty
            </Checkbox>
          </div>
        </div>
      </div>

      <Table<IReportItem>
        bordered
        loading={loading}
        dataSource={reportItems}
        pagination={false}
        rowKey="dateFormatted"
      >
        <Table.Column
          title="วันที่"
          align="center"
          dataIndex="dateFormatted"
          render={(text, record: IReportItem) => {
            if (record.dateFormatted === "รวม") {
              return <strong>{text}</strong>;
            }
            return text;
          }}
        />
        <Table.Column
          title="จำนวน"
          align="center"
          dataIndex="quantity"
          render={(text, record: IReportItem) => {
            if (record.dateFormatted === "รวม") {
              return <strong>{text}</strong>;
            }
            return (
              <Space>
                <span>{text}</span>
                {record.items.length > 0 && (
                  <Button
                    type="link"
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setOpenDetail(true);
                      setSelectedRow(record);
                    }}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>

      <Modal
        destroyOnClose
        open={openDetail}
        width={1000}
        onCancel={() => setOpenDetail(false)}
        footer={null}
        title={`รายละเอียดวันที่ ${selectedRow?.dateFormatted}`}
      >
        <Table<IRepair>
          bordered
          scroll={{ x: 800 }}
          dataSource={selectedRow?.items}
          pagination={false}
        >
          <Table.Column
            title="รูปถ่าย"
            width={100}
            render={(text, record: IRepair) => {
              return <Image src={record.photoURL} width={100} />;
            }}
          />
          <Table.Column
            title="หมายเหตุ"
            align="left"
            dataIndex="customerNote"
          />
          <Table.Column
            width={200}
            title="ชื่อลูกค้า"
            dataIndex="customerName"
          />
          <Table.Column
            title
            width={100}
            render={(text, record: IRepair) => {
              return (
                <Button
                  type="link"
                  onClick={() => {
                    setOpenView(true);
                    setSelectedUUID(record.receiptUUID);
                  }}
                >
                  ดูรายละเอียด
                </Button>
              );
            }}
          />
        </Table>
      </Modal>

      <Modal
        destroyOnClose
        width="100%"
        open={openView}
        onCancel={() => setOpenView(false)}
        footer={
          <Link to={`/repairments/${selectedUUID}`}>
            <Button block ghost type="primary">
              แก้ไข
            </Button>
          </Link>
        }
      >
        {selectedUUID && <ViewModal uuid={selectedUUID} />}
      </Modal>
    </>
  );
};
