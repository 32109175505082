import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  notification,
} from "antd";
import SignatureCanvas from "react-signature-canvas";

import { RepairmentForm } from "./RepairmentForm";
import { IReceipt } from "../../../entities";
import { useReceipt, useReturnHistory, useUpdateRepairment } from "../hooks";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  PrinterOutlined,
  ReloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { Printer } from "../utils";
import { Link } from "react-router-dom";

const printer = new Printer();
export const RepairmentViewForm: FC<{ uuid: string; mode?: string }> = ({
  uuid,
  mode = "normal",
}) => {
  const [isSigned, setIsSigned] = useState(false);
  const { data, loading, refetch } = useReceipt(uuid);
  const { update: updateRepairment, loading: updating } = useUpdateRepairment();
  const { data: returnHistory, refetch: refetchHistory } =
    useReturnHistory(uuid);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [form] = Form.useForm<IReceipt>();

  const handleSubmit = async (values: IReceipt) => {
    const orignalItems = data?.items || [];
    const newItems = values.items || [];
    const newReturnItems = newItems.filter((item) => {
      const orignal = orignalItems.find((o) => o.uuid === item.uuid);
      if (orignal?.isReturn) return false;
      return item.isReturn;
    });
    try {
      await updateRepairment(
        {
          ...values,
          receivedAt: new Date(values.receivedAt!.toString()),
        },
        signatureRef.current?.toDataURL() || "",
        isSigned ? newReturnItems : [],
        form.getFieldValue("isNotSaveReturn")
      );
      refetch();
      refetchHistory();
      notification.success({
        message: "Success",
        description: "Updated successfully",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };
  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFieldsValue({
      ...data,
      receivedAt: dayjs(data.receivedAt),
    });
  }, [data]);

  const handlePrintFixable = async () => {
    printer.printRepairItems(data!);
  };

  const handleSave = async () => {
    form.setFieldValue("isNotSaveReturn", true);
    await form.submit();
  };

  const handlePrintReturn = async () => {
    form.setFieldValue("isNotSaveReturn", false);
    await form.submit();
    const items = form.getFieldValue("items");
    const returnTodayItems = items.filter(
      (item: any) =>
        dayjs(item.returnAt).isSame(dayjs(), "day") && item.isReturn
    );
    const canvas = signatureRef.current?.getTrimmedCanvas();
    const ctx = canvas?.getContext("2d");

    const signatureData = ctx?.getImageData(
      0,
      0,
      canvas?.width!,
      canvas?.height!
    );

    printer.printReturnItems(
      data!,
      returnTodayItems?.length || 0,
      dayjs().format("DD/MM/YYYY"),
      signatureData
    );

    setTimeout(() => {
      setIsSigned(false);
      signatureRef.current?.clear();
    }, 1000);
  };

  const canvasWidth = useMemo(() => {
    const windowWidth = window.document.body.clientWidth;
    if (windowWidth < 500) return windowWidth - 64;
    return 500;
  }, []);

  return (
    <div className="repairment-view">
      <Spin spinning={loading}>
        <Form<IReceipt>
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          className="w-full"
          onFinish={handleSubmit}
        >
          <Form.Item name="isNotSaveReturn" hidden />
          <Form.Item name="uuid" hidden />
          <Form.Item name="createdAt" hidden />
          <Form.Item name="updatedAt" hidden />
          <Form.Item
            label={
              <div className="mt-2">
                RECEIPT DATE <br /> วันที่รับของ
              </div>
            }
            name="receivedAt"
            rules={[{ required: true }]}
          >
            <DatePicker
              disabled
              format="DD MMM YYYY"
              size="large"
              style={{ width: 250 }}
            />
          </Form.Item>
          <RepairmentForm isCreate={false} />

          <div>
            <Form.Item label="HISTORY">
              <Table
                bordered
                size="small"
                dataSource={returnHistory.sort((a, b) => {
                  return dayjs(b.returnAt).diff(a.returnAt);
                })}
                pagination={false}
                columns={[
                  {
                    title: (
                      <div>
                        DATE <br /> วันที่ทำรายการ
                      </div>
                    ),
                    dataIndex: "returnAt",
                    render: (date: Date) =>
                      dayjs(date).format("DD MMM YYYY HH:mm"),
                  },
                  {
                    title: (
                      <div>
                        ACKNOWLEDGEMENT
                        <br /> ลายเซ็นต์ผู้รับ
                      </div>
                    ),
                    dataIndex: "signature",
                    render: (signature: string) => (
                      <img className="h-24" src={signature} alt="signature" />
                    ),
                  },
                  {
                    title: (
                      <div>
                        TOTAL SHIPMENT
                        <br /> จำนวนสินค้าที่รับคืน
                      </div>
                    ),
                    dataIndex: "returnItems",
                    render: (items: string[]) => items.length,
                  },
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate>
            {(f) => {
              const orignalItems = data?.items || [];
              const newItems = f.getFieldValue("items") || [];
              const newReturnItems = newItems.filter((item: any) => {
                const orignal = orignalItems.find((o) => o.uuid === item.uuid);
                if (orignal?.isReturn) return false;
                return item.isReturn;
              });
              if (newReturnItems.length === 0) {
                return null;
              }
              return (
                <Form.Item
                  label={
                    <div className="mt-2">
                      SIGNATURE <br /> ลายเซ็นต์ลูกค้า
                    </div>
                  }
                >
                  <div className="flex items-end flex-row">
                    <div>
                      <div className="border rounded">
                        <SignatureCanvas
                          ref={signatureRef}
                          penColor="black"
                          backgroundColor="white"
                          maxWidth={1}
                          minWidth={1}
                          onBegin={() => {
                            setIsSigned(true);
                          }}
                          canvasProps={{
                            width: canvasWidth,
                            height: 300,
                            className: "sigCanvas",
                          }}
                        />
                      </div>

                      <Button
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          setIsSigned(false);
                          signatureRef.current?.clear();
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
              );
            }}
          </Form.Item>

          {mode === "normal" && (
            <Row>
              <Col offset={4}>
                <Space>
                  <Button
                    ghost
                    size="large"
                    loading={updating}
                    htmlType="button"
                    type="primary"
                    onClick={handleSave}
                    icon={<SaveOutlined />}
                  >
                    บันทึกรับซ่อม
                  </Button>

                  <Button
                    ghost
                    size="large"
                    loading={updating}
                    htmlType="button"
                    onClick={handlePrintFixable}
                    type="primary"
                    icon={<PrinterOutlined />}
                  >
                    ใบรับซ่อม
                  </Button>

                  <Form.Item shouldUpdate className="!mb-0">
                    {(f) => {
                      const orignalItems = data?.items || [];
                      const newItems = f.getFieldValue("items") || [];
                      const newReturnItems = newItems.filter((item: any) => {
                        const orignal = orignalItems.find(
                          (o) => o.uuid === item.uuid
                        );
                        if (orignal?.isReturn) return false;
                        return item.isReturn;
                      });
                      if (newReturnItems.length === 0) {
                        return null;
                      }
                      return (
                        <Popconfirm
                          title="หลังจากยืนยันแล้วจะไม่สามารถแก้ไขวันที่รับคืนได้"
                          okText="ยืนยัน"
                          cancelText="ยกเลิก"
                          onConfirm={handlePrintReturn}
                        >
                          <Button
                            ghost
                            size="large"
                            htmlType="button"
                            disabled={!isSigned}
                            loading={updating}
                            type="primary"
                            icon={<PrinterOutlined />}
                          >
                            บันทึกและปริ้นใบรับคืน
                          </Button>
                        </Popconfirm>
                      );
                    }}
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </div>
  );
};
