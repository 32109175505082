import { Typography } from "antd";
import { RepairmentCreateForm } from "./components";

export const CreateRepairment = () => {
  return (
    <div>
      <div className="flex justify-center flex-col items-center">
        <Typography.Title level={1} className="!m-0">
          RECEIPT CONFIRMATION
        </Typography.Title>
        <Typography.Title level={4} className="!mt-2">
          ใบยืนยันรับของ
        </Typography.Title>
      </div>
      <RepairmentCreateForm />
    </div>
  );
};
